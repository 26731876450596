import React from "react";
import Banner from "../components/Banner";
import Body from "../components/Body";
import "../App.scss";

const Home = () => {
  return (
    <>
      <Banner />
      <Body />
    </>
  );
};

export default Home;
