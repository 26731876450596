import Harpreet from "../img/people/harpreet.jpg";
import Nadia from "../img/people/nadia.jpg";
import Jaspal from "../img/people/jaspal.jpg";
export const team = [
  {
    id: 0,
    image: Harpreet,
    name: "Harpreet Marok",
    position: "Co-Founder / Cheif Software Engineer",
  },
  {
    id: 1,
    image: Nadia,
    name: "Nadia Aziz",
    position: "Design Director",
  },
  {
    id: 2,
    image: Jaspal,
    name: "Jaspal Marok",
    position: "Software Engineer",
  },
];
