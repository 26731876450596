import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ImageContentHover from "react-image-hover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCodeBranch, faCodeCommit, faCogs, faLightbulb, faRocket, faStar, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faGit } from "@fortawesome/free-brands-svg-icons";

export const whyWorkWithUs = [
    {
      id: 1,
      title: "Client-Focused",
      description: "We prioritize clear communication and collaboration, ensuring your vision is reflected in every part of the project.",
      image: faUsers,
    },
    {
      id: 2,
      title: "Expertise",
      description: "Our team brings years of hands-on experience, crafting solutions that are reliable, scalable, and built with precision. ",
      image: faCode,
    },
    {
      id: 3,
      title: "Efficient Process",
      description: "Our agile development process keeps projects on track and adaptable, making your journey from concept to launch seamless.",
      image: faCogs,
    },
    {
      id: 4,
      title: "Quality & Innovation",
      description: "We use the latest tech to deliver reliable, high-performance products that give you a competitive edge.",
      image: faCodeCommit,
    }
  ];

class WhyWorkWithUs extends Component {
  render() {
    return (
      <>
        <section className="why-work-with-us portfolio">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="portfolio-title text-white mb-5 mt-4">
                  <h2 className="title-font">Bespoke Software Development</h2>
                  <p className="mt-4 mb-4 title-font-2">
                  For every complex problem, there is an answer that is clear, simple, and wrong. We guide you with solutions that embrace the complexity and deliver clarity. Here are some of the reasons why you should work with us:
                  </p>
                </div>
              </div>

              </div>

              <div className="services">
                <div className="container text-white">
                <div className="row">
                {whyWorkWithUs.map((work) => (
                // show portfoilo boxes by mapping the array of images
                <div className="col-lg-6 col-md-6 col-sm-6" key={work.id}>
                  <div className="service-boxes-vertical">
                  <div className="service-icon">                    
                    <FontAwesomeIcon icon={work.image} size="6x" />
                  </div>
                  <div className="service-info-title">
                    <h3 className="service-name title-font-2">
                      {work.title}
                    </h3>
                  </div>
                  <div className="service-info">
                    <p className="service-description text-center title-font-2 mt-3">
                      {work.description}
                    </p>
                  </div>
                </div>
                </div>
              ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.works,
  };
};

export default connect(mapStateToProps)(WhyWorkWithUs);
