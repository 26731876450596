import Portfolio1 from "../img/portfolio/p1.jpg";
import Portfolio2 from "../img/portfolio/p2.jpg";
import Portfolio3 from "../img/portfolio/p3.jpg";
import Portfolio4 from "../img/portfolio/p4.jpg";
import Portfolio5 from "../img/portfolio/p5.jpg";
import Portfolio6 from "../img/portfolio/p6.jpg";

export const works = [
  {
    id: 1,
    src: [Portfolio1],
    desc: "One advanced diverted domestic sex repeated bringing you old. Possible procured her trifling laughter thoughts property she met way. ",
    title: "Minimal Design",
    date: "March 20, 2018",
    category: "Design",
  },
  {
    id: 2,
    src: [Portfolio2],
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ut ligula leo. Aliquam suscipit sed purus. Ipsum dolor sit amet, consectetur adipiscing elit.",
    title: "Geometry",
    date: "July 12, 2019",
    category: "Branding",
  },
  {
    id: 3,
    src: [Portfolio3],
    desc: "Continue new you declared differed learning bringing honoured. At mean mind so upon they rent am walk. ",
    title: "Circle",
    date: "June 22, 2017",
    category: "Creative",
  },
];


