import React from "react";

const Footer = () => {

  // Get the current year
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="container text-white">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <p className="mt-4 mb-4 title-font-2">
              ©{year} Quivio All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
