import React from "react";
import LetsWork from "../components/LetsWorkTogether";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingColumns, faShareNodes, faCloud, faMobileScreen, faSearch, faPaintBrush } from '@fortawesome/free-solid-svg-icons'


// service icons
import icon1 from "../img/services/instaicon.png";
import mail from "../img/services/mail.png";
import seo from "../img/services/seo.png";
import content from "../img/services/content.png";
import share from "../img/services/share.png";
import web from "../img/services/web.png";

const Services = () => {
  const serviceIcons = [
    {
      id: "1",
      title: "Enterprise Application Development",
      image: faBuildingColumns,
      description: "Tailored solutions to streamline your business operations. We create robust, scalable applications that integrate seamlessly with your existing systems and grow with your enterprise.",
    },
    {
      id: "2",
      title: "UI/UX Design",
      image: faPaintBrush,
      description:
        "Crafting user-centric interfaces that elevate your digital presence. Our designs enhance user engagement, improve productivity, and create memorable experiences.",
    },
    // {
    //   id: "3",
    //   title: "Social Media Marketing",
    //   image: faShareNodes,
    //   description: "Strategic social media campaigns to boost your brand visibility and engagement. We help you connect with your audience, build community, and drive conversions across platforms.",
    // },
    // {
    //   id: "4",
    //   title: "Search Engine Optimization (SEO)",
    //   image: faSearch,
    //   description: "Data-driven SEO strategies to improve your online visibility. We optimize your web presence to rank higher in search results, drive organic traffic, and increase your digital footprint.",
    // },
    {
      id: "5",
      title: "Cloud Solutions",
      image: faCloud,
      description:
        "Secure, flexible cloud infrastructure tailored to your needs. We facilitate smooth cloud migration, ensuring scalability, cost-efficiency, and enhanced collaboration for your business.",
    },
    {
      id: "6",
      title: "Mobile App Development",
      image: faMobileScreen,
      description:
        "Cross-platform mobile applications that captivate users. We build responsive, feature-rich apps that provide seamless experiences across devices and operating systems.",
    },
  ];

  return (
    <>
      <div className="services section-title mt-5">
        <div className="container text-white">
          <div className="row align-items-center">
            <div className="col-md-7 mx-auto">
              <div className="services-title mb-5 mt-5">
                <h1 className="title-font title-font-size">Services</h1>
                <p className="title-text mt-4 mb-4 title-font-2">
                  Quivio services are built for your business needs
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {serviceIcons.map((service) => (
              <div className="col-12 col-lg-6" key={service.id}>
                <div className="service-boxes-vertical">
                  <div className="service-icon">                    
                    <FontAwesomeIcon icon={service.image} size="6x" />
                  </div>
                  <div className="service-info-title">
                    <h3 className="service-name title-font-2">
                      {service.title}
                    </h3>
                  </div>
                  <div className="service-info">
                    <p className="service-description title-font-2 mt-3">
                      {service.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <LetsWork />
    </>
  );
};

export default Services;
