import React, { useEffect } from "react";
import { ReactComponent as Logo} from "../img/quivio-light.svg";
import "../App.scss";
import THREEScene, { LoadGlobals } from "./ThingThree";
import { Link } from "react-router-dom";

function Banner() {
  const bannerLogo = React.useRef(null);
  useEffect(() => {
    if(!bannerLogo.current) return;
    LoadGlobals();
    const scene = new THREEScene(bannerLogo.current);
    scene.onWindowResize();
    return () => {
      scene.destroy();
    }
  });
  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="banner-text text-left mt-4">
              <h1 className="title-font">
                We are <span className="text-customer">Quivio,</span> We help your bussiness <span className="text-customer">grow.</span>
              </h1>
              <p>We make awesome tools that solve real problems for you.</p>
              <Link to="/contact">
                <button type="submit" className="btn mt-5">
                  Get Started
                </button>
              </Link>
              
            </div>
          </div>
          <div className="banner-image col-xl-6 col-lg-6 col-md-6 d-lg-block">
            <div className="banner-area logo-container" ref={bannerLogo}>
              {/* <dotlottie-player src="https://lottie.host/0c15e3e2-5bbb-4b87-a156-3710f6301b1c/AUSAmdjPIn.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></dotlottie-player> */}
              {/* <Logo style={
                {
                  maxWidth: "500px",
                  maxHeight: "500px"
              }} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
